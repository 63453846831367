import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { Products } from "@components/products/Products"

const ProductsPage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const products = data.directus.items.products
  // @ts-ignore
  const seo = data.directus.items.seo
  const seoContent = seo.translations[0]

  return (
    <BaseLayout
      theme="gray"
      pageContext={pageContext}
      location={location}
      title={seoContent.title_products || "Продукція"}
      description={
        seoContent.description_products ||
        "У своїй роботі ми дотримуємося принципу, що кожен успішний бізнес є унікальним. Саме відміну від інших, вміння знайти в бізнесі свою винятковість забезпечує переваги, що дозволяють випередити конкурентів. Унікальний бізнес вимагає нестандартних рішень в плані забезпечення його обладнанням. Пропонуючи холодильне обладнання в Києві і по всій країні, ми готові до проектування нестандартних рішень, які будуть ідеально відповідати потребам наших клієнтів і умов їхнього бізнесу."
      }
      image={getSrc(seo.products_image.imageFile)}
    >
      <Products products={products} />
    </BaseLayout>
  )
}

export const query = graphql`
  query ProductsPage {
    directus {
      items {
        products {
          id
          sort
          slug
          translations(filter: { languages_code: { code: { _eq: "uk-UA" } } }) {
            title
            description
          }
        }
        seo {
          translations(filter: { languages_code: { code: { _eq: "uk-UA" } } }) {
            title_products
            description_products
          }
          products_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 627)
              }
            }
          }
        }
      }
    }
  }
`

export default ProductsPage
